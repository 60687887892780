<template>
  <div>
    <div class="col float-right">
      <q-btn-group v-show="editable&&(completeFlag || !deleteDisabled)" outline>
        <c-btn 
          v-show="editable&&!deleteDisabled&&isOld" 
          label="LBLREMOVE" 
          icon="remove" 
          @btnClicked="removePlan" />
        <c-btn 
          v-show="editable&&completeFlag" 
          :isSubmit="isComplete"
          :url="completeUrl"
          :param="assessPlan"
          mappingType="PUT"
          label="평가완료" 
          icon="check"
          @beforeAction="completeAction"
          @btnCallback="completeActionCallback" />
      </q-btn-group>
    </div>
    <c-tab
      ref="charmActionTab"
      type="vertical"
      :tabItems="tabItems"
      :height.sync="tabHeight"
      v-model="tab"
    >
      <template v-slot:default="tab">
        <component
          :is="tab.component"
          :key="tab.key"
          :height="height"
          :process.sync="tab.process"
          :param.sync="param"
          :planUpdateBtnData="param.planUpdateBtnData"
          :updateBtnData="param.actionUpdateBtnData"
          @assessComplete="assessComplete"
          @research="research"
        />
      </template>
    </c-tab>
  </div>
</template>

<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'charm-action',
  props: {
    param: {
      type: Object,
      default: () => ({
        ramRiskAssessmentPlanId: '',
        ramStepCd: '',
        searchRiskReduce: {
          flag: '',
          isSearch: false,
        },
        searchScenario: {
          flag: '',
          isSearch: false,
        },
        planUpdateBtnData: {
          title: 'LBLPLAN',
          flag: false,
          research: '',
        },
        actionUpdateBtnData: {
          title: '평가',
          flag: false,
          research: '',
        },
      }),
    },
    height: {
      type: String,
      default: function() {
        return '';
      },
    },
  },
  data() {
    return {
      tab: '1',
      tabItems: [],
      editable: true,
      isComplete: false,
      listUrl: '',
      completeUrl: '',
      deleteUrl: '',
    };
  },
  computed: {
    tabHeight() {
      let numHeight = this.height ? Number(this.$_.replace(this.height, 'px', '')) : 600;
      if (this.editable && (this.completeFlag || !this.deleteDisabled)) {
        numHeight = numHeight - 20
      }
      return numHeight + 'px';
    },
    completeFlag() {
      return this.tabItems 
        && this.tabItems.length > 0
        && this.$_.findIndex(this.tabItems, (item) => {
              return item.process.ramProcessAssessStepCd !== 'RPA0000005'
            }) === -1 // 전부 완료된 경우
        && this.param.ramStepCd === 'RRS0000010' // 감소대책 계획 및 실행 단계인 경우
    },
    assessPlan() {
      return {
        ramRiskAssessmentPlanId: this.param.ramRiskAssessmentPlanId,
        ramStepCd: this.param.ramStepCd,
        chgUserId: this.$store.getters.user.userId,
      }
    },
    isOld() {
      return Boolean(this.param.ramRiskAssessmentPlanId)
    },
    deleteDisabled() {
      return this.param.ramStepCd === 'RRS0000015'
    },
  },
  watch: {
    'param.searchScenario.flag'() {
      this.getList();
    },
    'param.planUpdateBtnData.research'() {
      this.getList();
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.ram.charm.targetProcess.url;
      this.completeUrl = transactionConfig.ram.assessPlan.complete.url
      this.deleteUrl = transactionConfig.ram.assessPlan.delete.url
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.$format(this.listUrl, this.param.ramRiskAssessmentPlanId);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.tabItems = [];
        this.$_.forEach(_result.data, item => {
          this.tabItems.push({
            key: uid(),
            name: item.processCd, icon: 'work_outline', label: item.processName, process: item, component: () => import(`${'./charmActionDetail.vue'}`)
          })
        })
        this.tab = _result.data[0].processCd
        this.$refs['charmActionTab'].setValue(this.tab)
      },);
    },
    assessComplete(stepCd) {
      this.getList();
      // stepper 이동
      if (stepCd === 'RRS0000015') {
        this.$emit('emitStep', {
          name: 'stepBystep',
          param: stepCd
        })
      }
    },
    research(place) {
      this.$emit('emitStep', {
        name: 'research',
        param: {
          place: place,
        }
      })
    },
    completeAction() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: 'MSGCOMPLETE', // 완료하시겠습니까?,
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.assessPlan.chgUserId = this.$store.getters.user.userId
          this.assessPlan.ramStepCd = 'RRS0000015'

          this.isComplete = !this.isComplete
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    completeActionCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      // 계획 상세 조회
      this.getList();
      // stepper 이동
      this.$emit('emitStep', {
        name: 'stepBystep',
        param: this.assessPlan.ramStepCd
      })
    },
    removePlan() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: 'MSGREMOVE', // 삭제하시겠습니까?,
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.param.ramRiskAssessmentPlanId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('emitStep', {
              name: 'closePopup'
            })
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
  }
};
</script>
